import React from 'react';

const NotFoundPage = () => (
  <div>
    <h1>Ups</h1>
    <p>Denne siden finnes ikke :(</p>
  </div>
);

export default NotFoundPage;
